import "@/styles/global.css";
import "react-phone-input-2/lib/style.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import Script from "next/script";
import { Fragment } from "react";

const App = ({ Component, pageProps }) => {
  return (
    <Fragment>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
      />

      <Script id="" strategy="lazyOnload">
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
              page_path: window.location.pathname,
              });
          `}
      </Script>
      <Component {...pageProps} />
    </Fragment>
  );
};

export default App;
